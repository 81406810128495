<template>
  <div class="PERInformation">
    <div class="PERInformation-head">
      <el-breadcrumb class="left-title" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>广告位管理</el-breadcrumb-item>
        <el-breadcrumb-item>Web端广告位</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="navs">
        <ul>
          <li
            v-for="(navsitem, navsindexs) in navs"
            :key="navsindexs"
            @click="TOnav(navsindexs)"
            :class="navsindex == navsindexs ? 'navsed' : ''"
          >
            {{ navsitem }}
          </li>
        </ul>
      </div>
    </div>

    <div class="top-up">
      <span @click="Asshow = true">新增</span>
    </div>

    <div class="PERInformation-table">
      <div class="tables">
        <el-table
          :data="dataBanner"
          :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
          :cell-style="cellstya"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="id"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            height="219"
            prop="source_url"
            width="300"
            label="Banner"
          >
            <template slot-scope="scope">
              <!-- <img :src="scope.row.photo" alt v-image-preview /> -->
              <el-image
                :src="scope.row.photo"
                class="table-img"
                :preview-src-list="[scope.row.photo]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="theme"
            label="Banner名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fileds"
            label="Banner位置"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="sortName"
            label="浏览数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="orderBy"
            label="点击数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fileds"
            label="点击率"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="state == 1 ? '已上架' : '已过期'"
            label="状态"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            width="180"
            label="购买时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="update_time"
            width="180"
            label="截止时间"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <el-button class="offSheIf" type="info" @click="flagD = true" plain
              >下架</el-button
            >
            <el-button class="listEdit" type="info" plain>编辑</el-button>
            <el-button type="danger" @click="flagD = true" plain
              >删除</el-button
            >
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <!-- <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination> -->
      </div>
    </div>

    <div v-show="flagD">
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="150px">
            <span class="deleteAd">确认下架该Banner？</span>
            <div class="btns">
              <el-button @click="toclose">取消</el-button>
              <el-button type="primary">确认</el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div class="over" @click="toclose"></div>
    </div>

    <div v-show="Asshow">
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="100px">
            <span class="formTitle">新增</span>
            <el-form-item label="Banner">
              <img :src="imgAd" alt />
            </el-form-item>
            <el-form-item label="Banner名称">
              <el-input type="text"></el-input>
            </el-form-item>
            <el-form-item label="Banner位置">
              <el-select
                style="width: 100%"
                filterable
                placeholder="请选择"
                v-model="selse"
              >
                <el-option label="学习主页" value="学习主页"></el-option>
                <el-option label="机构主页" value="机构主页"></el-option>
                <el-option label="私聊主页" value="私聊主页"></el-option>
                <el-option
                  label="直播课堂主页"
                  value="直播课堂主页"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-input type="date"></el-input>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-input type="date"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">添加</el-button>
              <el-button @click="toclose">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="over" @click="toclose"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WebAd",
  data() {
    return {
      navs: ["Banner", "热推", "启动页", "引导页", "首页弹窗"],
      navsindex: 0, //索引
      currentPage: 1,
      dataBanner: [],
      Asshow: false, //编辑弹框
      flagD: false, //
      imgAd: "",
      cellstya: {},
      state: "",
      selse: {},
    };
  },
  mounted() {},
  methods: {
    TOnav(index) {
      this.navsindex = index;
    },
    toclose() {
      this.Asshow = false;
      this.flagD = false;
    },
    GetListAd() {},
  },
};
</script>
<style scoped lang="less">
.PERInformation {
  position: relative;
  width: 100%;
  background: #ffffff;
  min-height: 1200px;
}
.PERInformation-head {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% -40px);
  padding: 20px 20px 0px 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.PERInformation-head
   .el-breadcrumb
   .el-breadcrumb__item
   .el-breadcrumb__inner {
  font-size: 20px;
  color: #434343;
  /* font-weight: bold; */
}
.PERInformation-head
   .el-breadcrumb
   .el-breadcrumb__item
   .el-breadcrumb__separator {
  color: #47cecf;
  width: 11px;
  color: 15px;
}
/* .PERInformation-head > .navs {
  width: 100%;
} */
.navs > ul {
  display: flex;
  list-style: none;
  padding: 0;
 
}
.navs > ul > li {
  margin-left: 40px;
@media screen and(max-width:768px){
  margin-left: 12px;
  
}
  cursor: pointer;
  color: #434343;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  /* font-weight: bold; */
}
.navsed {
  color: #32c1c2 !important;
  border-bottom: 3px solid #32c1c2;
}

/* 新增按钮 */
.top-up {
  margin-top: 10px;
  position: relative;
  width: 98%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
.top-up > span {
  display: inline-block;
  width: 74px;
  height: 32px;
  background: rgba(255, 65, 65, 1);
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

/* 弹框 */
.formTitle {
  font-size: 24px;
  font-weight: bold;
  margin-left: 140px;
}
.login {
  position: fixed;
  font-size: 24px;
  /* height: 800px; */
  width: 500px;
  border-radius: 10px;
  background-color: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2405;
}
.popupcontents {
  position: relative;
  width: 100%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.over {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
  z-index: 1000;
  opacity: 0.7;
}

.offSheIf {
  margin-left: 10px;
}
.listEdit {
  margin: 10px 0;
  margin-left: 10px;
}

.deleteAd {
  margin-top: 20px;
  width: 100%;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  color: #414141;
}
.btns {
  text-align: center;
  margin: 50px 0 20px 0;
}
</style>
